<template>
  <div class="d-inline-flex align-items-center text-decoration-underline">
    <a @click="showModal" role="button">
      <fa-icon :icon="['fas', 'video']" class="me-1"></fa-icon>
      <span>Clip {{ index + 1 }}</span>
    </a>

    <portal to="modal">
      <transition name="modal">
        <ExerciseVideoModal
          @close="hideModal"
          v-model="showExerciseVideoModal"
          v-if="showExerciseVideoModal"
          :video="video"
          :index="index"
        ></ExerciseVideoModal>
      </transition>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'ExerciseVideoListItem',
  components: {
    ExerciseVideoModal: () => import('@/components/modal/ExerciseVideoModal'),
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showModal() {
      this.showExerciseVideoModal = true;
    },
    hideModal() {
      this.showExerciseVideoModal = false;
    },
  },
  data() {
    return {
      showExerciseVideoModal: false,
    };
  },
};
</script>
